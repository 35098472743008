import React, {useState, useEffect} from 'react';
import { Card, CardGroup, Segment, Image, Divider, Header } from 'semantic-ui-react';

export const Compositions = (props) => {

    return (
        <>
        <Header as='h1'>Compositions</Header>
        <Image src="/images/mirrored-piano.png" size="medium" fluid centered style={{ padding: "30px" }} />
        </>
    )
}