import React, {useState, useEffect} from 'react';
import { Card, CardGroup, Segment, Image, Divider, Header } from 'semantic-ui-react';

export const Home = (props) => {

    return (
        <>
        <Header as='h1'>Home</Header>
        <Image src="/images/mirrored-piano.png" size="medium" centered style={{ padding: "30px" }} />
        </>
    )
}