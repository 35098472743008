import { Story } from "../../models/story";

export const STORIES: Story[] = [
    { title: "A Gift", filename: "Luis_Andrei_Cobo-A_Gift.pdf", icon: "pdf", year: 1998 },
    { title: "Diversion", filename: "Luis_Andrei_Cobo-Diversion.pdf", icon: "pdf", year: 1998 },
    { title: "Kill The Pain", filename: "Luis_Andrei_Cobo-Kill_the_Pain.pdf", icon: "pdf", year: 1998 },
    { title: "Mass Hysteria", filename: "Luis_Andrei_Cobo-Mass_Hysteria.pdf", icon: "pdf", year: 1998 },
    { title: "Regression", filename: "Luis_Andrei_Cobo-Regression.pdf", icon: "pdf", year: 1998 },
    { title: "Requiem", filename: "Luis_Andrei_Cobo-Requiem.pdf", icon: "pdf", year: 1999 },
    { title: "Subway", filename: "Luis_Andrei_Cobo-Subway.pdf", icon: "pdf", year: 1998 },
    { title: "Tenets of Dawn", filename: "Luis_Andrei_Cobo-Tenets_of_Dawn.pdf", icon: "pdf", year: 1998 },
    { title: "The Bond", filename: "Luis_Andrei_Cobo-The_Bond.pdf", icon: "pdf", year: 1998 },
    { title: "The Cleansing", filename: "Luis_Andrei_Cobo-The_Cleansing.pdf", icon: "pdf", year: 1998 },
    { title: "The Door", filename: "Luis_Andrei_Cobo-The_Door.pdf", icon: "pdf", year: 1997 },
    { title: "The Rite Way", filename: "Luis_Andrei_Cobo-The_Rite_Way.pdf", icon: "pdf", year: 1998 },
    { title: "The Setup", filename: "Luis_Andrei_Cobo-The_Setup.pdf", icon: "pdf", year: 2009 },
    { title: "The Symphony Of Life", filename: "Luis_Andrei_Cobo-The_Symphony_of_Life.pdf", icon: "pdf", year: 1998 },
    { title: "What Is Love?", filename: "Luis_Andrei_Cobo-What_Is_Love.pdf", icon: "pdf", year: 2007 },
    { title: "XG-50", filename: "Luis_Andrei_Cobo-XG-50.pdf", icon: "pdf", year: 1999 }
];