import React, {useState, useEffect, useRef} from 'react';
import { Card, CardGroup, Segment, Image, Divider, Icon, Tab, Header, Menu } from 'semantic-ui-react';
import { PageHeader } from './header';
import "../main.css";
import { Biography } from '../pages/biography';
import { Contact } from '../pages/contact';
import { Compositions } from '../pages/compositions';
import { Discography } from '../pages/discography';
import { Writings } from '../pages/writings';
import { Home } from '../pages/home';

export const MainPage = props => {

    const selectionTagMap = {
        "Discography": Discography,
        "Compositions": Compositions,
        "Writings": Writings,
        "Biography": Biography,
        "Contact": Contact
    }

    const isMobile = () => {
        var isMobile = window.matchMedia("only screen and (max-width: 760px)");
        return isMobile.matches ? true : false
    }

    const TAB_PANES = [
        { menuItem: { color: "purple", key: "Home", content: (<><Segment basic style={{ height: "150px" }}><Header as='h3' textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Home</Header>{!isMobile() && <Image centered src="/images/darwin.png" size="tiny"/>}</Segment></>)}, render: () => <Tab.Pane><Home  isMobile={isMobileDevice} scrollRef={scrollRef}/></Tab.Pane> },
        { menuItem: { color: "purple", key: "Biography", content: (<><Segment basic style={{ height: "150px" }}><Header as='h3' textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Biography</Header>{!isMobile() && <Image centered src="/images/dog.png" size="tiny" />}</Segment></>)}, render: () => <Tab.Pane><Biography  isMobile={isMobileDevice} scrollRef={scrollRef}/></Tab.Pane> },
        { menuItem: { color: "purple", key: "Discography", content: (<><Segment basic style={{ height: "150px" }}><Header as='h3' textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Discography</Header>{!isMobile() && <Image centered src="/images/music.png" size="tiny" />}</Segment></>) }, render: () => <Tab.Pane><Discography  isMobile={isMobileDevice} scrollRef={scrollRef}/></Tab.Pane> },
        { menuItem: { color: "purple", key: "Compositions", content: (<><Segment basic style={{height:"150px"}}><Header as='h3' textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Compositions</Header>{!isMobile() && <Image centered src="/images/mirrored-piano.png" size="tiny" />}</Segment></>) }, render: () => <Tab.Pane><Compositions  isMobile={isMobileDevice} scrollRef={scrollRef}/></Tab.Pane> },
        { menuItem: { color: "purple", key: "Writings", content: (<><Segment basic style={{ height: "150px" }}><Header as='h3' textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Writings</Header>{!isMobile() && <Image centered src="/images/computer.png" size="tiny" />}</Segment></>) }, render: () => <Tab.Pane><Writings  isMobile={isMobileDevice} scrollRef={scrollRef}/></Tab.Pane> },
        { menuItem: { color: "purple", key: "Contact", content: (<><Segment basic style={{ height: "150px" }}><Header as='h3' textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Contact</Header>{!isMobile() && <Image centered src="/images/couch.png" size="tiny" />}</Segment></>) }, render: () => <Tab.Pane><Contact isMobile={isMobileDevice} scrollRef={scrollRef}/></Tab.Pane> },
]

    const tabWidths = isMobile() ? null : TAB_PANES.length;
    const scrollRef = useRef(null);
    
    const [contentSelection, setContentSelection] = useState("Home");
    const [isMobileDevice, setIsMobileDevice] = useState(isMobile());
    const [tabIndex, setTabIndex] = useState(0);

    const handleCardClicked = (selection) => {
        setContentSelection(selection)
        sessionStorage.setItem("contentSelection", selection);
        executeScroll();
    }

    const executeScroll = () => {
        if (!isMobileDevice) return;
        setTimeout(() => { doScroll(); }, 250);
    }

    const doScroll = () => {
        scrollRef && scrollRef.current && scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const contactClicked = () => {
        setTabIndex(5);
        executeScroll();
    }

    const handleTabChanged = (e, data) => {
        doTabChanged(data.activeIndex);
    }

    const doTabChanged = (tabIndex) => {
        setTabIndex(tabIndex);
        sessionStorage.setItem("tabIndex", tabIndex);
        executeScroll();
    }
    
    useEffect(() => {
        startUp();
    }, []); 
    
    const startUp = () => {
        if (isMobile() && sessionStorage.getItem("contentSelection")) {
            handleCardClicked(sessionStorage.getItem("contentSelection"));
        }
        else if (!isMobile() && sessionStorage.getItem("tabIndex")) {
            doTabChanged(sessionStorage.getItem("tabIndex"));
        }
    }

    return (
        <>
            <Divider />
            <div style={{ float: 'left' }}>
                <Menu.Item href="https://open.spotify.com/artist/1R6ciAxUDhp9xoLqKBW6BF?si=g1SQD7fLS-2dWp-KlSrb9w" target="_blank"><Icon circular name="spotify" size="large" color="green" style={{ marginTop: "10px" }}/></Menu.Item>
                <Menu.Item href="https://soundcloud.com/luis-andrei-cobo" target="_blank"><Icon circular name="soundcloud" size="large" color="orange" style={{ marginTop: "10px" }}/></Menu.Item>
            </div>
            <div style={{ float: 'right' }}>
                <Menu.Item href="https://www.facebook.com/luis.andrei.cobo/" target="_blank"><Icon circular name="facebook official" size="large" color="blue" style={{ marginTop: "10px" }}/></Menu.Item>
                <Icon circular name="mail" size="large" color="purple" style={{ marginTop: "10px" }} link onClick={() => contactClicked()} />
            </div>
            <div style={{ fontFamily: "Cinzel Regular", fontSize: "clamp(4vh, 8vh, 7vw)", textAlign: "center", padding: "20px" }} onClick={() => handleCardClicked("Home")}>luis andrei cobo</div>
            <Divider clearing/>
            <Segment basic>
                {isMobileDevice &&
                    <Card.Group centered={true} itemsPerRow={5} stackable={true} doubling={true}>
                        <Card link raised onClick={() => handleCardClicked("Home")}>
                            <Card.Content>
                                <Card.Header textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Home</Card.Header>
                            </Card.Content>
                            <Image src="/images/darwin.png" size="medium" centered style={{ padding: "30px" }} />
                        </Card>
                        <Card link raised onClick={() => handleCardClicked("Biography")}>
                            <Card.Content>
                                <Card.Header textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Biography</Card.Header>
                            </Card.Content>
                            <Image src="/images/dog.png" size="medium" centered style={{ padding: "30px" }} />
                        </Card>
                        <Card link raised onClick={() => handleCardClicked("Discography")}>
                            <Card.Content>
                                <Card.Header textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Discography</Card.Header>
                            </Card.Content>
                            <Image src="/images/music.png" size="medium" centered style={{ padding: "30px" }} />
                        </Card>
                        <Card link raised onClick={() => handleCardClicked("Compositions")}>
                            <Card.Content>
                                <Card.Header textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Compositions</Card.Header>
                            </Card.Content>
                            <Image src="/images/mirrored-piano.png" size="medium" centered={true} style={{ padding: "30px" }} />
                        </Card>
                        <Card link raised onClick={() => handleCardClicked("Writings")}>
                            <Card.Content>
                                <Card.Header textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Writings</Card.Header>
                            </Card.Content>
                            <Image src="/images/computer.png" size="medium" centered={true} style={{ padding: "30px" }} />
                        </Card>
                        <Card link raised onClick={() => handleCardClicked("Contact")}>
                            <Card.Content>
                                <Card.Header textAlign="center" style={{ fontFamily: "Cinzel Regular" }}>Contact</Card.Header>
                            </Card.Content>
                            <Image src="/images/couch.png" size="medium" centered={true} style={{ padding: "30px" }} />
                        </Card>
                    </Card.Group>
                }
                {!isMobileDevice &&
                    <Tab activeIndex={ tabIndex } menu={{ fluid: true, vertical: false, tabular: 'left', size: 'small', className: "wrapped", widths: tabWidths }} panes={TAB_PANES} onTabChange={handleTabChanged} />
                }
                {isMobileDevice && contentSelection === "Home" &&
                    <Home isMobile={isMobileDevice} scrollRef={scrollRef} />
                }
                {isMobileDevice && contentSelection === "Biography" &&
                    <Biography isMobile={isMobileDevice} scrollRef={scrollRef} />
                }
                {isMobileDevice && contentSelection === "Contact" &&
                    <Contact isMobile={isMobileDevice} scrollRef={scrollRef}/>
                }
                {isMobileDevice && contentSelection === "Compositions" &&
                    <Compositions isMobile={isMobileDevice} scrollRef={scrollRef}/>
                }
                {isMobileDevice && contentSelection === "Discography" &&
                    <Discography isMobile={isMobileDevice} scrollRef={scrollRef}/>
                }
                {isMobileDevice && contentSelection === "Writings" &&
                    <Writings isMobile={isMobileDevice} scrollRef={scrollRef}/>
                }
            </Segment>
            <Segment basic fluid>
                <p style={{textAlign:"center", fontFamily:"Cinzel Regular", fontSize: "9pt"}}>&copy; 2023. Luis Andrei Cobo in affiliation with Over The Barline Publishing. All Rights Reserved.</p>
            </Segment>
        </>
    )
}