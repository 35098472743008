import React, {useState, useEffect} from 'react';
import { Card, CardGroup, Segment, Image, Divider, Header, Icon, Radio } from 'semantic-ui-react';
import { STORIES } from './data/stories';

export const Writings = (props) => {
    const SORT_BY = ["Year", "Title"];
    console.log(props.isMobile);

    const [sortedStories, setSortedStories] = useState(STORIES);
    const [sortBy, setSortBy] = useState("Year");
    const [isMobileDevice, setIsMobileDevice] = useState(props.isMobile);

    useEffect(() => {
        sortStories("Year");
    }, []);

    const sortStories = (how) => {
        setSortBy(how);
        console.log("Sorting Stories by " + how);
        if (how === "Year") {
            setSortedStories(sortedStories.sort((a, b) => a.year - b.year));            
        }
        if (how === "Title") {
            setSortedStories(sortedStories.sort((a, b) => a.title.localeCompare(b.title)));
        }
    }

    const sortChanged = (e, data) => {
        let localSortBy = data.checked ? "Year" : "Title";
        setSortBy(localSortBy);
        sortStories(localSortBy);
    }


    return (
        <>
            <div ref={props.scrollRef}>
            <Header as='h1' style={{ fontFamily: "Cinzel Regular" }}>Writings</Header>
                <Divider />
                <p style={{ fontFamily: "Cinzel Regular", fontWeight: "bolder" }}>This is a collection of short stories written between 1998 and 2009.</p>
                <p style={{ fontFamily: "Cinzel Regular", fontWeight: "bolder" }}>Sort by:&nbsp;&nbsp;Title&nbsp;&nbsp;<Radio style={{ height: "10px" }} toggle onChange={sortChanged} checked={sortBy === "Year"} />&nbsp;&nbsp;Year</p>
            <Divider/>
            {!isMobileDevice && <Image src="/images/programming.png" size="medium" floated="left" style={{ padding: "30px" }} />}
            <Card.Group itemsPerRow={isMobileDevice?2:5}>
                {
                    sortedStories.map((story) => 
                        <Card link href={"/stories/pdf/" + story.filename} key={story.filename} target="_blank">
                            <Card.Content header={story.title} />
                            <Card.Content>
                                <Icon color="red" name="file pdf" size="large"/>
                            </Card.Content>
                            <Card.Content extra>{story.year}</Card.Content>
                        </Card>
                    )
                }
                </Card.Group>
                </div>
        </>
    )
}