import React, {useState, useEffect} from 'react';
import { Card, CardGroup, Segment, Image, Divider, Header, Tab } from 'semantic-ui-react';


export const Biography = (props) => {


    return (
        <>   
            <div ref={props.scrollRef}></div>
            {props.isMobile && <><Image src="/images/trumpet.jpg" size="medium" centered style={{ padding: "10px" }} /><br/></>}
            {!props.isMobile && <Image src="/images/trumpet.jpg" size="medium" floated="left" style={{ padding: "30px" }} />}
            <div style={{"fontFamily": "trebuchet ms, arial, helvetica", "textAlign": "justify"}}>
                <p><big>Luis&nbsp;Andrei&nbsp;Cobo</big> was born in Brooklyn, NY in 1972. At the
                age of 4, his parents Lidia, a corporate secretary, and Luis, a credit analyst,
                took him and his two sisters, Maria and Larissa, to see the film <i>Star Wars</i>.
                The music for the film enthralled him and began a lifelong love of orchestral
                and film music which surprised his entire family. Shortly after he began
                learning piano under the direction of his Father, an amateur pianist, but like
                many children that age, did not particularly enjoy it. He also developed a love
                of performing during the elementary school years, being cast as Captain Hook in
                the musical <i>Peter Pan</i> in the third grade, Snoopy in the musical <i>You're
                a good man Charlie brown</i> in the fifth. As well, half-hearted learning
                continued until at the age of 12 his parents took him to see the film <i>Amadeus</i>.
                This movie completely changed his perspective and inspired him to become a
                composer. The very next day, he was sent to the one month he ever went to
                summer camp, and they had a piano on the premises. There, he composed his first
                piece of music and that sparked the journey he embarked on for decades to come.
                At that point, Cobo dove into piano playing in earnest and quickly progressed
                into advanced technical ability.</p>
                <p>Upon entering Middle School, he immediately signed up for
                Band, and was, originally, assigned to play the French Horn. After a few months
                of that he asked to be switch to trumpet as the trumpet parts were far more
                melodious and fun to play than the Horn parts of beginner ensemble works. It
                was at this time he also attempted to compose his first piece for wind band. It
                wasn't long before he realize he needed to learn about transposition and orchestration
                and found books in the public library to study. His trumpet playing progressed
                quickly and he joined the Brooklyn Borough Symphonic Wind Band moving quickly
                from third trumpet to First trumpet in a few short weeks, and, in a few years,
                became the lead trumpet player. When he was 16 years old, the Brooklyn Borough
                Band held its annual performance at Carnegie Hall, marking Cobo's Carnegie Hall
                debut as a Trumpet player with a short solo of George Gershwin's <i>Summertime</i>.
                During these years, he composed a major work for Symphonic Wind Band called <i>The
                Kings Entrance</i>, which garnered an excellent assessment from composer Robert
                Starer, and inspired him to compose additional works for orchestra. At the same
                time, Cobo decided to pursue higher education as a concert pianist and
                composer. </p>
                <p>His High School orchestra teacher, George Beard, encouraged
                him in this pursuit by allowing him to borrow every instrument from the
                orchestra and learn basics on each one over the course of 4 years, as the
                assertion was this would assist in having a better understanding of
                orchestration. As well, Mr. Beard noticed he had a good ear and photographic
                memory and would provide various exercises and tasks to hone these particular
                skills: he was asked to go to a music store, look at a random page of music,
                commit it to memory, go home and write it down; he was asked to transcribe
                music he had heard once, and subsequently, was taught to be able to reproduce
                simple piano performances on sight and hearing only. </p>
                <p>As auditions approached, Cobo prepared a program which
                included Beethoven's <i>Waldstein Sonata</i> and Chopin's <i>Heroic Polonaise</i>;
                and composed his first piano concerto (in short score), which, stylistically, was
                drawn from late romanticism. He was accepted to Manhattan School of Music and
                Mannes College as a composition major and was also offered either Composition
                or Piano Major at Boston Conservatory. Cobo accepted the program at Manhattan
                School of Music in 1990 wherein he won the Absolut Vodka Composers award. Completing
                both his Bachelors '94 and Masters degrees '96, he studied with illustrious composers
                as David Noon, Aaron Jay Kernis, Richard Danielpour and Giampaolo Bracali, and electronic
                music with Elias Tanenbaum. It was during this period that he developed life long
                friendships with notable artists such as composer Gene Pritsker, soprano Tamara
                Hardesty and pianist Tamami Honma, among many others. During this period he
                composed three orchestral works: <i>Dream Fragments</i>, <i>Scherzo</i> for
                Piano and Orchestra, and <i>Death</i> for Baritone and Orchestra, and developed
                a love of writing art songs, with performances by the great Jennifer Arnold and
                the sultry Tamara Hardesty, to name a few. As well, Cobo's most performed work,
                <i>Slow Motion</i>, was composed during this period.</p>
                <p>After college, however, life shifted, and he married pianist Christina Anastasiou
                and in 1997 she gave birth to their son Luis Anthony. Simultaneously, he was
                awarded the Concert Artists Guild Composers Award which resulted in the
                commissioning of three since unperformed and subsequently shelved chamber
                works: <i>Prelude and Millennium</i> for Oboe and String Quartet, <i>Postlude</i>
                &nbsp;for Baritone, Flute, Cello and Piano, and <i>Personal Demons</i> for Piano Trio.
                However, shortly after, there was a dearth of opportunities and difficult times
                nudged Cobo to pursue gainful work elsewhere. In 1999 he took a job building
                HTML pages for an online cosmetics company at the height of the dotcom bubble.
                This job propelled him into a nearly 25 year career as a software engineer,
                working in such companies as Google, DoubleClick, ThinkMap, Bank Of America and
                Deutsche Bank. </p>
                <p>This career shift precipitated a gap in his work as a
                composer as his new career demanded enormous amounts of time to move from
                junior to senior engineer in a short period of time. In 2005, however, during a
                fateful trip to Barnes and Noble with his family he read a poetry periodical
                and therein discovered a poet whose poetry he was intrigued by. Later that
                evening in an attempt to get more information he inadvertently clicked the
                wrong link and in a moment of kismet landed on the page of poet Jennifer Michael
                Hecht whose work was immediately inspiring. With permission, he set the poem <i>On
                the Strength of all Conviction and the Stamina of Love</i>, for Mezzo-Soprano
                and Piano, in his first complete work since the commissions of 1996. It was
                recorded at Manhattan School of Music with Mezzo-Soprano Anne-Marie Wilcox-Daehn
                and pianist Lois Anderson.</p>
                <p>In December of 2006, Composer and Mentor Giampaolo Bracali passed. Cobo's
                friend, and a former pupil of Giampaolo Bracali, composer and impressario Gene Pritsker, conceived and
                produced a memorial concert for this occasion. Cobo was asked to compose
                something fitting and premiere it at this concert. Having been intrigued by the
                work of Hecht, he asked the poet if there were any works in her catalog that
                would be fitting for a memorial tribute, to which she said there were none she
                could point to. However, she offered Cobo three unpublished poems with the
                express permission that lines can be culled from each of them into a 4<sup>th</sup>
                poem that might actually suit the occasion. The result was a poem that the poet
                subsequently called <i>The Disarrange</i> and was the basis for the work <i>The
                Disarrange</i> for Soprano, Cello and Piano. When Cobo completed the
                composition his first thought was to show Bracali this work, and it was in that
                moment that it truly hit home that his former mentor was truly gone. The premiere
                was performed by soprano Lynn Norris, cellist Daniel Barrett and pianist Anna
                Smirny, on April 15, 2007, in affiliation with the International Street Cannibals
                performers, and it marked the first public performance of Cobo's work since
                leaving college in 1996. During this same year, Cobo and his wife separated and
                subsequently divorced due to irreconcilable differences.</p>
                <p>In the years to come, most of the musical compositions were
                in the form of Art Song, with several more works based on the poetry of Jennifer
                Michael Hecht, namely <i>Felonies</i>, <i>History</i> and <i>Fall In Time</i>.
                Many collaborative performances with Composers Concordance and International
                Street Cannibals inspired additional works, including <i>Ellipsis</i> for 4
                Celli, <i>Less Than Three Minuets</i>, <i>Demeter</i>, <i>One</i>, and <i>On The
                        Other Hand</i>, among others.</p>
                <Image src="/images/at-the-piano.jpg" size="medium" floated="right" fluid style={{ padding: "30px" }} />
                <p>In 2013, an arrangement was made with the Seniors Orchestral
                Society of New York City for a new work for symphony orchestra to be premiered in
                their third season. Over the next year, <i>Symphony Nostalgique</i> was
                composed, an 18-minute work for Orchestra. Budget issues, however, forced the
                orchestra to cease operations before their third season could be completed and
                the work was never premiered. During the composition of this symphony, Tamami
                convinced Cobo that any and all sketches should be kept and some were turned into
                short piano works called Musings, one of which was premiered and recorded in
                2021 later by Honma on the album <i>The Disarrange</i>.</p>
                <p>In 2015, Cobo married software engineer Elena Senkina and they had a child,
                Oliver. Shortly after, he was commissioned by Tamami Honma and the Cambrian
                Orchestra to compose a new piano concerto. Due to time constraints of being a
                new father, and an ongoing full time job as a software engineer, Cobo completed
                2 of the three planned movements, <i>Just Breathe</i> and <i>Anxious</i>, and both
                were premiered in July of 2017. The work went on to garner the “Best of the Bay”
                award in new music performance for that season. </p>
                <p>In 2018, Honma, along with Soprano Heather Green,
                commissioned Cobo for a new set of songs for the CalArte Ensemble. The two
                works were scored for Soprano and Piano Quintet and included <i>Time Does not
                Bring Relief</i> on a poem of Edna St. Vincent Millay, and <i>Worms - a monodrama</i>
                &nbsp;on poetry by Heather Green. This work was premiered in San Jose and then received
                its New York premiere in 2019. At this time, Cobo also gave a lecture at the
                National Opera Center on Film Scores, specifically how heroes are depicted in
                movies, to a visiting class of music students from the Vantage School in Hong Kong.
                Transcript and material from this lecture were later published in that school's
                publication. Around this time, Cobo and Senkina separated and were divorced
                also due to irreconcilable differences.</p>
                <p>In early 2020, the global SARS-COV-2 pandemic erupted around the world and Cobo
                lost interest in composing for some time and no new pieces were written until
                2022. However, several years prior, Tamara Hardesty recorded several of Cobo's
                songs at the National Opera Center which were not yet released, Cobo decided to
                commission Tamami Honma to record several piano works, including some world
                premieres, which would be woven into the tapestry of art songs on an album
                titled <i>The Disarrange</i>. The album was released globally in October of 2021.</p>
                <p>In 2022, in an attempt to rekindle his composing habit, Cobo
                composed two new songs: <i>Love Comes Quietly</i>, on poetry of Robert Creeley,
                and <i>Time is Always Waiting</i>, on poetry of Heather Green. Both works were
                premiered that year. Currently, Tamara Hardesty is planning to record both of
                those works in early April 2023 for subsequent release as singles. In the interim,
                Cobo has been sketching a few new pieces, including a set for Brass Ensemble, inspired
                by Calliope Brass, and additional art songs on poetry of Hecht. Stay Tuned.</p>
                <p>Presently, Cobo resides in Jersey City with his son, Oliver, and his dog,
                Darwin.</p>
            </div>
            <Image src="/images/dog.png" size="medium" centered fluid style={{ padding: "30px" }} />
        </>
    )
}