import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainPage } from './main';

const AppRoutes = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<MainPage/>}/>
        </Routes>
    </BrowserRouter>
);
export default AppRoutes;