import React, {useState, useEffect} from 'react';
import { Card, CardGroup, Segment, Image, Divider, Header, Container } from 'semantic-ui-react';

export const Discography = (props) => {

    return (
        <>
            <div ref={props.scrollRef}></div>
            <Header as='h1' style={{fontFamily: "Cinzel Regular"}}>Discography</Header>
            <Divider />
            <Segment fluid basic style={{backgroundColor: "#2471A3", color:"white", fontFamily:"Trebuchet MS, Arial, Helvetica"}}>
                <Image link size="medium" floated="left" src="/images/albums/song-cycles.jpg" style={{padding: "0px 20px 0px 10px"}} href="https://open.spotify.com/album/0EuWnmLHQciX4nd2QwaLFS?si=K6HCo6h5Ra2-uOdJLqgiYQ" target="_blank" title="Song Cycles Album - Link to Spotify"/>
                <p>Released in 2015 on the <a href="https://composersconcordance.wixsite.com/composersconcordance/compcordrec" target="_new" class="link-on-dark">Composers Concordance Records</a> label,
                    this compilation album features collections of songs from various composers performed and recorded exquisitely. The song cycle <i>History</i> composed by Luis Andrei Cobo
                    and based on three poems of Jennifer Michael Hecht was recorded in Lou Brown Studios in New York City and features soprano <a href="http://lynnnorris.sitehappy.com/" target="_new" class="link-on-dark">Lynn Norris</a> and pianist <a href="http://www.amirpiano.com/" target="_new" class="link-on-dark">Amir Khosrowpour</a>. 
                </p>
                <Container>
                    <ul><b>Tracks by Luis Andrei Cobo:</b>
                        <li>Felonies</li>
                        <li>History</li>
                        <li>On the strength of all conviction and the stamina of love</li>
                    </ul>
                </Container>
                
            </Segment>
            <Divider clearing />
            <Segment fluid basic style={{ backgroundColor: "#883E92", color: "white", fontFamily: "Trebuchet MS, Arial, Helvetica" }}>
                <div style={{height:"270px", width:"270px", float:"right", backgroundColor:"#dfdfdd"}}>
                    <Image link size="medium" centered src="/images/albums/the-disarrange.png" style={{ padding: "12px" }} href="https://open.spotify.com/album/2l0x4k1uWw1OiHyiBBW13K?si=kjOs2TqpSSO1vtiRcvtl3g" target="_blank" title="The Disarrange Album - Link to Spotify" bordered />
                </div>
                <p>Released in 2021 on the Over the Barline label, this compilation album features collections of songs and piano solos spanning 30 years of compositional work. For a complete overview of the album and all of the program notes, <a href="https://www.thedisarrange.com" target="_new" class="link-on-dark">click here!</a></p>
                <Container>
                    <ul style={{columns:"3"}}><b>Tracks by Luis Andrei Cobo:</b>
                        <li>Prelude #1</li>
                        <li>Felonies</li>
                        <li>Le Petite Basque</li>
                        <li>The Disarrange</li>
                        <li>One</li>
                        <li>On the strength of all conviction and the stamina of love</li>
                        <li>This Is Just To Say</li>
                        <li>Demeter</li>
                        <li>Time Does Not Bring Relief</li>
                        <li>Worms</li>
                        <li>Slow Motion</li>
                        <li>Dark Hills</li>
                        <li>After a Hundred Years</li>
                        <li>I Thought My Heart Had Forgotten</li>
                        <li>Musing</li>
                    </ul>
                </Container>
            </Segment>
            <div style={{float:"clear"}}><br/><br/><br/><br/></div>
            <Divider clearing />
            <Segment fluid basic style={{ backgroundColor: "#2471A3", color: "white", fontFamily: "Trebuchet MS, Arial, Helvetica" }}>
                <Image link size="medium" floated='left' src="/images/albums/musing.png" style={{ padding: "12px" }} href="https://open.spotify.com/album/40ZLb8zF7vXftlIzT39ZRA?si=u0WN-2rUT4y5sgfZ56PbtQ" target="_blank" title="Musing - Single - Link to Spotify" bordered />
                <p style={{ marginLeft: "30px" }}>Released in 2022 on the Over the Barline label, this single is a personal recording made by Luis Andrei Cobo, playing himself. </p>
                <p>This track was concieved during the composition of <i>Symphony Nostalgique</i> on the advice of pianist Tamami Honma, who insisted that any ideas that come up that won't be used in the symphony should make their way into sketches for later use. This particular sketch became a complete work in a short afternoon.</p>
                <p>This piece was unlike any of the other material in the symphony. When it was composed, it took some time to research to be sure this wasn't being borrowed or stolen from elsewhere because there is a familar flavor to it. That familiarness is what makes this work so poignant as it immediately grabs the attention of the listener and draws you into the world of beautiful and sweet melancholy.</p>
                <p>It appears also as the final track of the album <i>The Disarrange</i>, as it has the characteristics of a postlude, or farewell. </p>
                <br /><br />
            </Segment>
            <Divider clearing />
            <Segment fluid basic style={{ backgroundColor: "#883E92", color: "white", fontFamily: "Trebuchet MS, Arial, Helvetica" }}>
                <Image link size="medium" floated='right' src="/images/albums/love-comes-quietly.png" style={{ padding: "12px" }} href="https://open.spotify.com/album/31kRumrhgbEWAKCs5EX3oD?si=GJQCZYo9RL-3EAkf5q3sZQ" target="_blank" title="Love Comes Quietly - Single - Link to Spotify" bordered />
                <p>Soprano <a href="http://www.tamarahardesty.com" class="link-on-dark">Tamara Hardesty</a>, accompanied by Luis Andrei Cobo, perform this song written on the poetry of Robert Creeley.</p>
                <p>This song is the first song I wrote during the COVID-19 pandemic, and it took 2 years to get to this. Tamara gave me a few poems to choose from and this one spoke to me the most.</p>
                <p>It took me a little while to realize, afterward, that the entire work hinges on the four note motif that is sung with the words "In the old ways": the motive itself is used in the accompaniment, as both a chromatic color, driving the harmony, and then as a direct motive that expands at each climax.</p>
                <p>This was recorded at the National Opera Center in NYC in April of 2023.</p>
                <br /><br />
            </Segment>
            <Divider clearing />
            <Segment fluid basic style={{ backgroundColor: "#2471A3", color: "white", fontFamily: "Trebuchet MS, Arial, Helvetica" }}>
                <Image link size="medium" floated='left' src="/images/albums/time-is-always-waiting.png" style={{ padding: "12px" }} href="https://open.spotify.com/album/3j4Pk9Pwt46geYYIrOA6dz?si=2161513e504a481a" target="_blank" title="Time is always waiting - Single - Link to Spotify" bordered />
                <p>Soprano <a href="http://www.tamarahardesty.com" class="link-on-dark">Tamara Hardesty</a>, accompanied by Luis Andrei Cobo, perform this song written on the poetry of <a href="https://www.heathergreenvoice.com/" class="link-on-dark">Heather Green</a>.</p>
                <p>This song is the second song I wrote during the COVID-19 pandemic, and also the second song I have written on poetry of Heather Green, the first being <a href="https://open.spotify.com/track/1U1KG4taPumPAqlyEhiT20?si=8c4ff8da3ce742c7" class="link-on-dark">Worms (a monodrama)</a> which Heather also premiered.</p>
                <p>Heather's poetry inspires different colors from my music than other poets have, and it expands my willingness to explore darker dissonances. However, as this, too, was written during the pandemic, when I wrote it, I started with the lines "Merciful the moments, that crumble before bone" as the idea for setting those came immediately. The penultimate chord I had discovered during this work to be exactly how I was feeling about the state of the world, and myself.</p>
                <p>This was recorded at the National Opera Center in NYC in April of 2023.</p>
                <br /><br />
            </Segment>
            <Divider clearing />

        </>
    )
}